<template>
  <section>
    <div class="content-header">
      <h2>Works cited</h2>
    </div>
    <div class="content-wrapper">
      <p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared. </p>
      <ul>
        <li class="pb-3">De Gannes, N. (editor) et. al. (2021). Ivey School of Business HBA Onboarding Module. Available Online.</li>
        <li class="pb-3">Western University. (n.d.). <em>More than Words A Guide to Land Acknowledgements at Western University</em>. Adobe Spark. <a href="https://spark.adobe.com/page/FSblzCQD2HCI5/" target="_blank">https://spark.adobe.com/page/FSblzCQD2HCI5/</a></li>
        <li class="pb-3"><em>Native Land Digital</em>. (2021, October 8). Native-Land.Ca. <a href="https://native-land.ca/" target="_blank">https://native-land.ca/</a></li>
        <li class="pb-3">Crenshaw, K. (1989). Demarginalizing the Intersection of Race and Sex: A Black Feminist Critique of Antidiscrimination Doctrine, Feminist Theory and Antiracist Politics. University of Chicago Legal Forum, 1989(1), 31.</li>
        <li class="pb-3">Diverse Representation in Leadership: A Review of Eight Canadian Cities (Diversity Leads). (2020). The Diversity Institute. <a
            href="https://www.ryerson.ca/diversity/reports/DiversityLeads_2020_Canada.pdf"
            target="_blank"
          >www.ryerson.ca/diversity/reports/DiversityLeads_2020_Canada.pdf </a></li>
        <li class="pb-3">Five charts that show what systemic racism looks like in Canada. (2020, June 4).
          CTVNews.<br><a
            href="https://www.ctvnews.ca/canada/five-charts-that-show-what-systemic-racism-looks-like-in-canada-1.4970352"
            target="_blank"
          >www.ctvnews.ca/canada/five-charts-that-show-what-systemic-racism-looks-like-in-canada- 1.4970352 </a></li>
        <li class="pb-3">Government of Canada, S. C. (2019, February 27). Diversity of the Black population in Canada: An overview. <a
            href="https://www150.statcan.gc.ca/n1/pub/89-657-x/89-657-x2019002-eng.htm "
            target="_blank"
          >www150.statcan.gc.ca/n1/pub/89-657-x/89-657-x2019002-eng.htm </a></li>
        <li class="pb-3">Government of Canada, S. C. (2021, March 29). Police-reported hate crime in Canada,
          2019. <a
            href="https://www150.statcan.gc.ca/n1/pub/85-002-x/2021001/article/00002-eng.htm"
            target="_blank"
          >www150.statcan.gc.ca/n1/pub/85-002-x/2021001/article/00002-eng.htm</a></li>
        <li class="pb-3">Kendi, I. X. (2019). How to Be an Antiracist by Ibram X. Kendi. Penguin Random House
          Canada. <a
            href="https://www.penguinrandomhouse.ca/books/564299/how-to-be-an-antiracist-by-ibram-x- kendi/9780525509288"
            target="_blank"
          >www.penguinrandomhouse.ca/books/564299/how-to-be-an-antiracist-by-ibram-x- kendi/9780525509288 </a></li>
        <li class="pb-3">Neustaeter, B. (2021, February 10). “Basically non-existent”: Focus on mainstream trailblazers leaves Black LGBTQ+ people out of the spotlight. CTVNews. <a
            href="https://www.ctvnews.ca/canada/basically-non-existent-focus-on-mainstream-trailblazers-leaves-Black-lgbtq-people-out-of-the-spotlight-1.5303046"
            target="_blank"
          >www.ctvnews.ca/canada/basically-non-existent-focus-on-mainstream-trailblazers-leaves-Black-lgbtq-people-out-of-the-spotlight-1.5303046</a></li>

          <!-- <li class="pb-3">policy paper—African Canadian Legal Clinic. (n.d.). Studylib.Net. Retrieved June 1, 2021,
          from <br><a
            href="https://studylib.net/doc/8102332/policy-paper---african-canadian-legal-clinic"
            target="_blank"
          >studylib.net/doc/8102332/policy-paper---african-canadian-legal-clinic </a>
        </li> -->
        <li class="pb-3">The Black Experience Project. (2017). <br><a
            href="https://www.environicsinstitute.org/projects/project-details/black-experience-project-in-the-gta "
            target="_blank"
          >www.environicsinstitute.org/projects/project-details/black-experience-project-in-the-gta </a></li>
        <li class="pb-3"><a
            href="https://www.bbc.com/news/world-middle-east-16047709  "
            target="_blank"
          >www.bbc.com/news/world-middle-east-16047709 </a></li>
        <li class="pb-3"> <a
            href="https://www.ohchr.org/Documents/Issues/Religion/Islamophobia-AntiMuslim/Civil%20Society%20or%20Individuals/Noor-ICLMG-ISSA.pdf"
            target="_blank"
          >www.ohchr.org/Documents/Issues/Religion/Islamophobia-AntiMuslim/Civil%20Society%20or%20Individuals/Noor-ICLMG-ISSA.pdf </a></li>
        <li class="pb-3">Abella, Irving. "Anti-Semitism in Canada" The Canadian Encyclopedia, 7 January 2021, Historica Canada. <a
            href="https://www.thecanadianencyclopedia.ca/en/article/jewish-canadians"
            target="_blank"
          >www.thecanadianencyclopedia.ca/en/article/jewish-canadians.</a> Accessed 24 August 2021.</li>
        <li class="pb-3">Abella, Irving M., and Harold Martin Troper. None Is Too Many : Canada and the Jews of Europe, 1933-1948 . Lester & Orpen Dennys, 1982.</li>
        <li class="pb-3">American Jewish Year Book 2019: The Annual Record of the North American Jewish Communities Since 1899. Vol. 119, Springer International Publishing, doi:10.1007/978-3-030-40371-3.</li>
        <li class="pb-3">Bard, Mitchell, “Jewish Claim to the Land of Israel”, (June 20, 2021), <br><a
            href="https://www.jewishvirtuallibrary.org/jewish-claim-to-the-land-of-israel"
            target="_blank"
          >www.jewishvirtuallibrary.org/jewish-claim-to-the-land-of-israel </a></li>
        <li class="pb-3">Bennhold, Katrin. “Part 1: Shadow Army?” Day X, Garrison, L., Toeniskoetter, C., Roberts, K. New York Times, date. nytimes.com, <br><a
            href="https://www.nytimes.com/2021/05/19/podcasts/far-right-german-extremism.html?action=click&module=RelatedLinks&pgtype=Article "
            target="_blank"
          >www.nytimes.com/2021/05/19/podcasts/far-right-german-extremism.html?action=click&module=RelatedLinks&pgtype=Article </a></li>
        <li class="pb-3">Bennhold, Katrin. “Part 2: In the Stomach” Day X, Garrison, L., Toeniskoetter, C., Roberts, K. New York Times, date. nytimes.com, <br><a
            href="https://www.nytimes.com/2021/05/19/podcasts/far-right-german-extremism.html?action=click&module=RelatedLinks&pgtype=Article"
            target="_blank"
          >www.nytimes.com/2021/05/19/podcasts/far-right-german-extremism.html?action=click&module=RelatedLinks&pgtype=Article </a></li>
        <li class="pb-3">Bennhold, Katrin. “Part 3: Blind Spot 2.0” Day X, Garrison, L., Toeniskoetter, C., Roberts, K. New York Times, date. nytimes.com, <br><a
            href="https://www.nytimes.com/2021/05/19/podcasts/far-right-german-extremism.html?action=click&module=RelatedLinks&pgtype=Article"
            target="_blank"
          >www.nytimes.com/2021/05/19/podcasts/far-right-german-extremism.html?action=click&module=RelatedLinks&pgtype=Article </a></li>
        <li class="pb-3">Bennhold, Katrin. “Part 4: Franco A.” Day X, Garrison, L., Toeniskoetter, C., Roberts, K. New York Times, date. nytimes.com, <a
            href="https://www.nytimes.com/2021/05/19/podcasts/far-right-german-extremism.html?action=click&module=RelatedLinks&pgtype=Article "
            target="_blank"
          >www.nytimes.com/2021/05/19/podcasts/far-right-german-extremism.html?action=click&module=RelatedLinks&pgtype=Article </a></li>
        <li class="pb-3">Bennhold, Katrin. “Part 5: Defensive Democracy” Day X, Garrison, L., Toeniskoetter, C., Roberts, K. New York Times, date. nytimes.com, <br><a
            href="https://www.nytimes.com/2021/05/19/podcasts/far-right-german-extremism.html?action=click&module=RelatedLinks&pgtype=Article "
            target="_blank"
          >www.nytimes.com/2021/05/19/podcasts/far-right-german-extremism.html?action=click&module=RelatedLinks&pgtype=Article </a></li>
        <li class="pb-3">Brym, Robert, et al. “2018 Survey of Jews in Canada: Executive Summary (Links to an external site.).” American Jewish Year Book 2019, Springer International Publishing, 2020, pp. 247–61, doi:10.1007/978-3-030-40371-3_7.</li>
        <li class="pb-3">DellaPergola, Sergio. “World Jewish Population, 2019.” American Jewish Year Book 2019, Springer International Publishing, 2020, pp. 263–353, doi:10.1007/978-3-030-40371-3_8.</li>
        <li class="pb-3">Envoy, Jemima. "Synagogue Attacks And Slurs: Jewish Community Rocked By Rise In Anti-Semitism Amid Israel- Gaza Fighting" Forbes, 20 May 2021,<br> <a
            href="https://www.forbes.com/sites/jemimamcevoy/2021/05/20/synagogue-attacks-and-slurs-jewish-community-rocked-by-rise-in-anti-semitism-amid-israel-gaza-fighting/?sh=4bb47a522627"
            target="_blank"
          >www.forbes.com/sites/jemimamcevoy/2021/05/20/synagogue-attacks-and-slurs-jewish-community-rocked-by-rise-in-anti-semitism-amid-israel-gaza-fighting/?sh=4bb47a522627 </a></li>
        <li class="pb-3">Sarna, Jonathan D. The American Jewish Experience. Holmes & Meier, 1986.</li>
        <li class="pb-3">Schonfeld, Stuart. "Jewish Canadians" The Canadian Encyclopedia, 18 March 2020, Historica Canada.<br> <a
            href="https://www.thecanadianencyclopedia.ca/en/article/jewish-canadians"
            target="_blank"
          >www.thecanadianencyclopedia.ca/en/article/jewish-canadians</a> Accessed 15 August 2021. </li>
        <li class="pb-3">Vigod, Bernard L. The Jews in Canada. Canadian Historical Association, 1984.</li>
        <li class="pb-3">“Zionism.” Merriam-Webster.com Dictionary, Merriam-Webster, <br><a
            href="https://www.merriamwebster.com/dictionary/Zionism"
            target="_blank"
          >www.merriamwebster.com/dictionary/Zionism. </a> Accessed 25 Aug. 2021.</li>
        <li class="pb-3">Angelyn, Frances. "The ‘model minority’ myth explained. What you need to know about how it has propped up anti-Asian racism in Canada." <em>The Star</em>, 27 March 2021, <br><a
            href="https://www.thestar.com/news/canada/2021/03/27/the-model-minority-myth-explained-what-you-need-to-know-about-how-it-has-propped-up-anti-asian-racism-in-canada.html"
            target="_blank"
          >https://www.thestar.com/news/canada/2021/03/27/the-model-minority-myth-explained-what-you-need-to-know-about-how-it-has-propped-up-anti-asian-racism-in-canada.html.</a> Accessed 25 August 2021. </li>
        <li class="pb-3">Abu-Iaban, Baha. "Arab Canadians" The Canadian Encyclopedia, 16 December 2013, <em>Historica Canada</em>. <a
            href="https://www.thecanadianencyclopedia.ca/en/article/arabs"
            target="_blank"
          >www.thecanadianencyclopedia.ca/en/article/arabs.</a> Accessed 24 August 2021. </li>
        <li class="pb-3">Guo, Shibao, and Don J. DeVoretz. “The Changing Face of Chinese Immigrants in Canada.” <em>Journal of International Migration and Integration</em>, vol. 7, no. 3, Springer Netherlands, 2006, pp. 275–300, doi:10.1007/s12134-006-1014- 0. </li>
        <li class="pb-3">Joy, Amanda. "Vietnamese Canadians" The Canadian Encyclopedia, 5 March 2018, <em>Historica Canada</em>. <a
            href="https://www.thecanadianencyclopedia.ca/en/article/vietnamese"
            target="_blank"
          >www.thecanadianencyclopedia.ca/en/article/vietnamese</a> Accessed 24 August 2021. </li>
        <li class="pb-3">Joy, Amanda. "Thai Canadians" The Canadian Encyclopedia, 5 March 2018, <em>Historica Canada</em>. <a
            href="https://www.thecanadianencyclopedia.ca/en/article/thais"
            target="_blank"
          >www.thecanadianencyclopedia.ca/en/article/thais. </a>Accessed 24 August 2021. </li>
        <li class="pb-3">Lindsay, Colin. <em>The West Asian Community in Canada, 2001 [microform]</em> . Statistics Canada, Social and Aboriginal Statistics Division, 2007. </li>
        <li class="pb-3">Said, Edward W. Orientalism. New York: Pantheon Books, 1978. Print.</li>
        <li class="pb-3">Sunahara, Ann. "Japanese Canadians" The Canadian Encyclopedia, 27 September 2019, <em>Historica Canada</em>. <a
            href="https://www.thecanadianencyclopedia.ca/en/article/japanese-canadians"
            target="_blank"
          >www.thecanadianencyclopedia.ca/en/article/japanese-canadians. </a>Accessed 23 August 2021. </li>
        <li class="pb-3">Wallace, Sarah I. "Racial Segregation of Asian Canadians" The Canadian Encyclopedia, 12 October 2018, <em>Historica Canada</em><br><a
            href="https://www.thecanadianencyclopedia.ca/en/article/segregation-of-asian-canadians"
            target="_blank"
          >www.thecanadianencyclopedia.ca/en/article/segregation-of-asian-canadians. </a>Accessed 23 August 2021. </li>
      </ul>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
